@use "sass:meta";
@use "variables.scss";

// tailwind styles
@tailwind base;

html {
  @apply text-sm xxl:text-base;
}

@tailwind components;
@tailwind utilities;


@include meta.load-css("components/calendar.scss");

@include meta.load-css("main.scss");
@include meta.load-css('admin.scss');
@include meta.load-css("responsiveness.scss");
