@use "../variables";

.Calendar__day {
  &.-selected, 
  &.-selectedStart,
  &.-selectedEnd {
    color: #F5E003;
  }
}

.Calendar__monthYear {
  button {
    font-weight: 600;
    padding: 0;
    color: variables.$text-gray-5;
    font-size: 16px;
  }
}

.calendar-custom {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 8px 24px rgba(0, 0, 0, 0.15);

  .filters {
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
  }
}
