@use "variables";

.splash-sidebar{
    background-color: blue;
    width: 32.2vw;
    height: 100%;
    flex-shrink: 0;
}

@media screen and (max-width: variables.$md_breakpoint) {
    .splash-sidebar{
        height: 120px;
        overflow: hidden;
        width: 100%;
    }
}

.navigation-bar {
    width: 10.5vw;
    min-width: 10.5vw;
}

@media screen and (max-width: variables.$tab_breakpoint) {
    .navigation-bar{
        width: 200px;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 1;
        max-width: 300px;
        transform: translateX(-100%);
        transition: all .4s ease;
        &.active{
            transform: translateX(0);
        }
    }
    .hamburger{
        display: block;
    }

}

.form-control-disp{
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    @media screen and (max-width: 518px) {
        grid-template-columns: auto;
        grid-row-gap: 0px
    }
}

.mobile-admin-distributor-pagination{
    display: block
}
.desktop-admin-distributor-pagination{
    display: none
}
@media screen and (min-width: 995px) {
    .mobile-admin-distributor-pagination{
        display: none
    }
    .desktop-admin-distributor-pagination{
        display: block
    }
}

.order-summary-table{
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: 20px;
    @media screen and (max-width: 1248px) {
        grid-template-columns: auto;
    }
}

